import React from "react";

import Nav from "../nav/nav";
import "./header.scss";

const Headeralt = () => (
  <header className="site-header alt">
    <Nav />
  </header>
)

export default Headeralt
