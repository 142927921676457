/**
 * CTA component
 */

import React from "react"
import "./cta.scss"

const Cta = () => {
  return (
    <section className="cta">
		<div className="container">
            <div className="flex">
                <div className="text">Intéressé par notre formation ? </div>
                <div className="links">
                    <a href="https://www.technocite.be/index.php/fr/component/detailsform/?form=1273" className="button" target="_blank" rel="noopener noreferrer">J'envoie ma candidature</a>
                    <a href="mailto:contact@techyourchance.be">J'ai une question</a>
                </div>
            </div>
		</div>
	</section>
  )
}

export default Cta
