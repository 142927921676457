import React from "react"

import Layoutalt from "../components/layoutalt"
import SEO from "../components/seo"
import Cta from "..//components/cta/cta"
import Partners from "..//components/partners/partners"

const FormationPage = () => (
  <Layoutalt>
    <SEO title="L'offre de formation TYC" />

    <section className="container small">
      <h1>L'offre de formation TYC</h1>
      <p>Afin de répondre aux exigences des métiers du numérique, nous avons développé avec nos partenaires une offre de formation brève, intensive, pertinente mais accessible au plus grand nombre.</p>
      <h2>Les infos pratiques</h2>
      <p>La formation <strong>TechYourChance, devenez designer/développeur Web</strong> se déroule en deux phases distinctes.</p>
      <p>La première phase dite d'e-learning a pour but de vous aider à appréhender les bases des concepts de programmation et d'algorithmie. Elle se déroulera du 10 au 21 aout 2020 et sera ouverte à tous.</p>
      <p>La seconde phase se déroule en présentiel à l'<a href="https://www.ee-campus.be/" target="_blank" rel="noopener noreferrer">Eurometropolitan e-campus de Tournai</a>. Vous travaillerez en sous-groupes sur des projets d'entreprise réels. Elle se déroulera du 31 aout au 27 novembre 2020 et sera accessible à 12 personnes après un entretien de motivation.</p>
    </section>

    <section className="container small">
      <h2>Structure de la formation</h2>
      <ul>
        <li>analyse et gestion de projet numérique
          <ul>
            <li>s’initier à la méthode SCRUM</li>
            <li>planifier et organiser un projet en méthode agile</li>
            <li>s’organiser  en équipe et collaborer</li>
            <li>participer à des phases d’idéation et de brainstorming</li>
          </ul>
        </li>
        <li>apprendre à utiliser des outils collaboratifs et de partage</li>
        <li>designer des interfaces web
          <ul>
            <li>apprendre à concevoir des wireframes</li>
            <li>appréhender les notions de design manifest, design system, design atomique et de grille</li>
            <li>concevoir des interfaces responsive et mobile first</li>
          </ul>
        </li>
        <li>développer des applications web
          <ul>
            <li>apprendre à concevoir des applications javascript</li>
            <li>développer des solutions HTML5/CSS3</li>
            <li>tester et documenter ses développements</li>
            <li>optimiser les applications développées</li>
            <li>appréhender les risques de sécurité de solutions web</li>
            <li>assurer la maintenance et les mises à jour</li>
          </ul>
        </li>
        <li>découvrir le travail et l’organisation en équipe</li>
      </ul>
    </section>

    <section className="container small">
      <h2>Les valeurs de notre formation</h2>
      <p>Durant une période de trois mois, vous apprendrez à collaborer à la conception de projets réels d’entreprises, afin de développer des applications qui ont du sens. Notre formation se concentre sur la conception et le développement d’applications numériques avec un focus important sur des valeurs essentielles au bon fonctionnement de projets numériques telles que la <strong>collaboration, l’entraide, le respect et la prise de responsabilités</strong>.</p>
    </section>

    <Cta />
    <Partners />
  </Layoutalt>
)

export default FormationPage
